import React from 'react'

const contact = ({ extend }) => (
  <a
    className={
      extend ||
      `dib relative w-100 w-auto-ns b ph3 pv2 tc ba bw1 b--black black link dim`
    }
    href="mailto:info@sprucegrovecapital.com"
  >
    Contact us
  </a>
)

export default contact
