import React from 'react'

import { Location } from '@reach/router'

const year = new Date().getFullYear()

class Footer extends React.Component {
  setFooter = location => {
    switch (location.pathname) {
      default:
        return 'dib relative w-100 bg-white brand-blue'
    }
  }

  render() {
    return (
      <Location>
        {({ location }) => {
          return (
            <footer
              className={`${this.setFooter(location)} f5 fw5 lh-copy footer pv4`}
            >
              <div className="db center mw8 ph4 pv3">
                <div className="dib relative w-100">
                  <a
                    href="mailto:info@sprucegrovecapital.com"
                    className="link b dim f4 brand-blue"
                  >
                    info@sprucegrovecapital.com
                  </a>
                  <p className="f7 db ttu ma0">
                    &copy; {year} Spruce Grove Capital
                  </p>
                </div>
              </div>
            </footer>
          )
        }}
      </Location>
    )
  }
}

export default Footer
