import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            title={title}
            link={[
              {
                rel: 'apple-touch-icon',
                sizes: '180x180',
                href: '/icons/apple-touch-icon.png',
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '32x32',
                href: '/icons/favicon-32x32.png',
              },
              {
                rel: 'icon',
                type: 'image/png',
                sizes: '16x16',
                href: '/icons/favicon-16x16.png',
              },
              {
                rel: 'manifest',
                href: '/icons/site.webmanifest',
              },
              {
                rel: 'mask-icon',
                href: '/icons/safari-pinned-tab.svg',
                color: '#43c6ac',
              },
              {
                rel: 'shortcut icon',
                href: '/icons/favicon.ico',
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: 'og:image',
                content: 'https://sprucegrovecapital.com/social.jpg',
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: data.site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: 'viewport',
                content: 'width=device-width, initial-scale=1.0',
              },
              {
                name: 'msapplication-config',
                content: '/icons/browserconfig.xml',
              },
              {
                name: 'msapplication-TileColor',
                content: '#00aba9',
              },
              {
                name: 'theme-color',
                content: '#43c6ac',
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
