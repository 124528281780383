// import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import AnchorLink from 'react-anchor-link-smooth-scroll'

import { Location } from '@reach/router'
import { Link } from 'gatsby'

import Contact from '../components/contact'

import Logo from '../images/logo-white.svg'

const navLinkClass = 'dib v-mid link dim inherit f7 f5-l fw5 br3 pointer mr4'

class Header extends React.Component {
  state = {
    isTop: false,
  }

  componentDidMount() {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 100
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    })
  }

  render() {
    const { siteTitle } = this.props
    return (
      <Location>
        {({ location }) =>
          location.pathname === '/' ? (
            <nav
              className={`nav fixed left-0 top-0 dib w-100 z-2 brand-blue lh-copy bg-white f5 tl ${
                this.state.isTop ? 'pv3' : 'pv0 not-top'
              }`}
            >
              <div className="db mw8 center ph4 ph3-l">
                <div className="dib flex-l justify-between middle-xs">
                  <div className="dib relative v-mid w-auto w-40-l">
                    <AnchorLink
                      offset="100"
                      className={navLinkClass}
                      href="#focus"
                    >
                      Focus
                    </AnchorLink>
                    <AnchorLink
                      offset="100"
                      className={navLinkClass}
                      href="#team"
                    >
                      Team
                    </AnchorLink>
                  </div>
                  <div className="dn dib-l relative v-mid w-auto w-10-ns tc">
                    <Link
                      className="logo dib relative bg-brand-blue pa2 w3 h3 br-pill pa3"
                      to="/"
                    >
                      <img
                        className="dib relative h-100 v-mid"
                        src={Logo}
                        alt={siteTitle}
                      />
                    </Link>
                  </div>
                  <div className="dib relative v-mid w-auto w-40-l tr">
                    <Contact extend={navLinkClass} />
                  </div>
                </div>
              </div>
            </nav>
          ) : null
        }
      </Location>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
